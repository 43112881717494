<template>
  <div class="clearfix">
    <div class="">
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 1,
          block: openTab === 1,
        }"
        v-if="!showFlutter"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/1200px-M-PESA_LOGO-01.svg.png"
              class="w-44 mx-auto"
            />
            <div class="payment-description-wrapper">
              <div class="payment-text">
                <span>PAYBILL: {{ paybill }}</span>
                <span>ACCOUNT: {{ accountNumber }}</span>
              </div>
              <p style="margin-top: 10px; font-weight:600">
                Once you receive a confirmation SMS,
                <a :href="this.baseURL + '/fundraiser/' + accountNumber"
                  >click here</a
                >
                to view your live fundraiser Share Page
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 2,
          block: openTab === 2,
        }"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/Airtel_Uganda-Logo.wine.png"
              class="w-44 mx-auto"
            />
            <div class="payment-description-wrapper">
              <div class="payment-text">
                <span>Business Name: {{ businessName }}</span>
                <span v-if="champion_code"
                  >Account Number: {{ champion_code }}</span
                >
                <span v-else>Reference: {{ accountNumber }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 3,
          block: openTab === 3,
        }"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/Equitel_Kenya_Logo.png"
              class="w-44 mx-auto"
            />
            <div class="payment-description-wrapper">
              <div class="payment-text">
                <span>Business Number: {{ paybill }}</span>
                <span v-if="champion_code"
                  >Account Number: {{ champion_code }}</span
                >
                <span v-else>Account Number: {{ accountNumber }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 4,
          block: openTab === 4,
        }"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/telkom-tkash.png"
              class="w-44 mx-auto"
            />
            <div class="payment-description-wrapper">
              <div class="payment-text">
                <span>DIAL: *160*2*1#</span>
                <span>PAYBILL: {{ paybill }}</span>
                <span>ACCOUNT: {{ accountNumber }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-content clearfix"
        v-bind:class="{
          hidden: openTab !== 5,
          block: openTab === 5,
        }"
        v-if="showFlutter"
      >
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <img
              src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/flutterwave-logo-vector.png"
              class="w-56 mx-auto"
            />
            <div class="payment-description-wrapper">
              <!-- Submit Button -->
              <div class="buttons-w">
                <button
                  id="show-modal"
                  @click="flutterModal = true"
                  class="button mb-3"
                >
                  Donate Now
                </button>
              </div>
              <Teleport to="body">
                <!-- use the modal component, pass in the prop -->
                <modal :show="flutterModal" @close="flutterModal = false">
                  <template #header>
                    <div>
                      <h3>{{ campaign_name }}</h3>
                    </div>
                  </template>
                  <template #body>
                    <ul>
                      <li>Fill the form below, and Click Donate</li>
                      <li>
                        Follow the steps & complete checkout process with your
                        mobile money provider
                      </li>
                      <li>
                        You'll receive one receipt from Flutterwave, and one
                        from M-CHANGA
                      </li>
                    </ul>
                    <form
                      method="post"
                      id="flutter_form"
                      name="flutter_form"
                      @submit.prevent="flutterCheckout(currencyConfig)"
                    >
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend">Phone Number:</span>
                        <input
                          type="number"
                          class="form-control unit-input__input"
                          :placeholder="
                            'Int\'l format e.g. +' +
                            local_country_codes[myCountryCode] +
                            '1234567890'
                          "
                          name="flutter_phone"
                          id="flutter_phone"
                          AUTOCOMPLETE="OFF"
                          required
                          minLength="12"
                        />
                      </div>
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend"
                          >Amount({{ myCurrency }}):</span
                        >
                        <input
                          type="number"
                          class="form-control unit-input__input"
                          :placeholder="
                            'Enter amount to donate (' + myCurrency + ')'
                          "
                          name="flutter_amount"
                          id="flutter_amount"
                          AUTOCOMPLETE="OFF"
                          required
                          min="10"
                        />
                      </div>
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend">Name:</span>
                        <input
                          type="text"
                          class="form-control unit-input__input"
                          placeholder="Enter your full name"
                          name="flutter_name"
                          id="flutter_name"
                          AUTOCOMPLETE="OFF"
                          required
                        />
                      </div>
                      <div class="form-group unit-input">
                        <span class="unit-input__prepend">Email:</span>
                        <input
                          type="email"
                          class="form-control unit-input__input"
                          placeholder="Enter a valid email address"
                          name="flutter_email"
                          id="flutter_email"
                          AUTOCOMPLETE="OFF"
                          required
                        />
                        <input
                          id="flutter_account"
                          name="flutter_account"
                          :value="accountNumber"
                          type="hidden"
                          readonly
                          autocomplete="off"
                        />
                      </div>
                      <div
                        id="flutter_error"
                        class="error-msg"
                        v-show="flutter_error_show"
                      >
                        {{ flutter_error }}
                      </div>
                      <div
                        id="flutter_success"
                        class="success"
                        v-show="flutter_success_show"
                      >
                        {{ flutter_success }}
                      </div>
                      <div>
                        <button
                          style="
                            width: 100%;
                            background-color: #75ac12;
                            font-weight: 600;
                            font-size: 24px;
                            color: white;
                            border-radius: 65px;
                          "
                          class="button"
                          @click="flutterCheckout(currencyConfig)"
                        >
                          Donate
                        </button>
                      </div>
                    </form>
                  </template>
                  <template #footer>
                    &copy; M-Changa {{ currentYear }}
                  </template>
                </modal>
              </Teleport>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content clearfix" v-if="myCountryCode == 'NENE'">
        <div class="payment-method-content">
          <div class="payment-method-inner">
            <p>OR</p>
            <img src="@/assets/images/wave.png" class="w-40 mx-auto" />
            <div class="payment-description-wrapper">
              <div class="payment-text">
                <span>Envoyez votre don à</span>
                <span>Téléphone: {{ this.xaf_msisdn }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from './DonationModal.vue';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      form_m: {
        mpesa_amount: '',
      },
      showModal: false,
      flutterModal: false,
      currentYear: new Date().getFullYear(),
      flutter_error: '',
      flutter_error_show: false,
      flutter_success: '',
      flutter_success_show: false,
      isDisabled: false,
      showFlutter: false,
    };
  },
  async created() {
    if (this.isFlutterwave != 'no') {
      this.showFlutter = this.isFlutterwave;
    } else {
      // start watching this prop
      const unwatch = this.$watch('isFlutterwave', () => {
        this.showFlutter = this.isFlutterwave;
        unwatch();
      });
    }
  },

  methods: {
    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },
    validatePhone: function (phone) {
      const codes = this.allowed_country_codes;
      var errorMessage = '';
      if (parseInt(phone.length) < 10) {
        errorMessage +=
          'Provide phone number in correct international format\n';
        return errorMessage;
      }
      let myCountryCode = this.myCountryCode;
      var intl_code = this.local_country_codes[myCountryCode];
      if (phone.substring(0, intl_code.toString().length) != intl_code) {
        errorMessage += 'Phone must be in specified international format!';
        return errorMessage;
      }
      return 'valid';
    },
    flutterCheckout: function (currencyConfig) {
      this.isDisabled = true;
      this.flutter_error = '';
      this.flutter_error_show = false;
      var phone = document.getElementById('flutter_phone').value;
      const validPhone = this.validatePhone(phone, 'fw');
      if (validPhone != 'valid') {
        this.isDisabled = false;
        this.flutter_error = validPhone;
        this.flutter_error_show = true;
        setTimeout(() => (this.till_error_show = false), 3000);
        return -1;
      }
      const amount = parseInt(document.getElementById('flutter_amount').value);
      if (isNaN(amount) || amount < currencyConfig.activation_amount) {
        this.isDisabled = false;
        this.flutter_error =
          'Minimum amount - ' +
          currencyConfig.currency_code +
          ' ' +
          currencyConfig.activation_amount;
        this.flutter_error_show = true;
        setTimeout(() => (this.flutter_error_show = false), 3000);
        return -1;
      }
      var FW_Channel = '';
      if (this.available_countries.includes(this.myCountryCode)) {
        let c_code = this.myCountryCode;
        FW_Channel = 'mobilemoney' + c_code.toLowerCase();
      } else {
        this.isDisabled = false;
        this.flutter_error = 'Invalid Source - check ' + this.myCountryCode;
        this.flutter_error_show = true;
        setTimeout(() => (this.flutter_error_show = false), 3000);
        return -1;
      }
      var ContributorName = document.getElementById('flutter_name').value;
      if (ContributorName == '' || ContributorName.length < 7) {
        this.isDisabled = false;
        this.flutter_error = 'Name is required!';
        this.flutter_error_show = true;
        setTimeout(() => (this.flutter_error_show = false), 3000);
        return -1;
      }
      var flutterData = {
        CampaignName: this.campaign_name,
        TransactionID: document.getElementById('flutter_account').value,
        Campaign_ID: this.campaign_id,
        PhoneNumber: phone,
        ContributorName: ContributorName,
        Currency: currencyConfig.currency_code,
        FW_Channel: FW_Channel,
        Amount: amount,
        Email: document.getElementById('flutter_email').value,
        callback_url: window.location.href + '/success',
      };
      this.flutter_success_show = true;
      this.flutter_success = 'Processing Donation request...';

      fetch(this.apiURL + `/service/donation/flutter-checkout`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(flutterData),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.link) {
            window.location.replace(data.link);
          }
        })
        .catch(function (error) {
          location.reload();
          console.error('Error:', error);
        });
    },
  },
  props: {
    accountNumber: {
      default: 0,
      type: Number,
    },
    paybill: {
      default: '',
    },
    businessName: {
      default: '',
    },
    campaign_name: {
      default: '',
      type: String,
    },
    campaign_id: {
      default: '',
      type: String,
    },
    currencyConfig: {
      default: '',
    },
    isFlutterwave: {
      default: 'no',
    },
    openTab: {
      default: 1,
    },
    myCountryCode: {
      default: '',
    },
    myCurrency: {
      default: '',
    },
  },
};
</script>
<style scoped>
.payment-text,
.payment-text span {
  font-size: 14px;
  font-weight: bold;
}
.flogos {
  width: 40%;
}
button:disabled,
button[disabled] {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}
.error-msg {
  color: #d63301;
  background-color: #ffccba;
  text-align: center;
  padding: 5px;
}
.info {
  color: #00529b;
  background-color: #bde5f8;
  text-align: center;
  padding: 5px;
}
.success {
  color: #4f8a10;
  background-color: #dff2bf;
  text-align: center;
  padding: 5px;
}

#show-modal {
  text-align: center;
  vertical-align: middle;
  display: inline-block;
}
ul {
  list-style: disc;
}
.unit-input {
  border: #dfdfdf solid 1px;
  display: inline-flex;
  border-radius: 4px;
  overflow: hidden;
  font-family: sans-serif;
}

.unit-input__input {
  border: 0;
  padding: 0.5em;
  font-size: 1em;
  width: 100%;
  margin: 0;
  border-radius: 0;
}
.unit-input__prepend,
.unit-input__append {
  background: #f4f4f4;
  padding: 0.5em;
  border: #dfdfdf solid 0;
  flex-grow: 0;
}

.unit-input__prepend {
  border-right-width: 1px;
}
.payment-description-wrapper .input-group .form-control {
  padding-right: 0;
}
.unit-input {
  width: 100% !important;
}
</style>
