<script>
export default {
  props: {
    show: Boolean,
  },
};
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <button id="close_m" @click="$emit('close')">X</button>
            <slot name="header">default header</slot>
          </div>

          <div class="modal-body">
            <slot name="body">default body</slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button class="button">Donate</button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 5px !important;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
  text-align: center !important;
  display: block !important;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
}

.modal-body {
  position: relative;
  padding: 0 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

/* combine with style */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin: 0;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: serif;
}

.modal-body h4 {
  margin: 0;
  font-size: 18px;
  letter-spacing: 1px;
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  line-height: inherit;
  color: #75ac12;
  white-space: normal;
  font-weight: 600;
  font-family: serif;
  text-align: center;
}

.modal-body {
  margin: 2px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.form-control {
  border-radius: 10px;
  border: 1px solid #ccc;
  height: 40px !important;
  box-shadow: none;
  margin-bottom: 15px;
  margin-bottom: 15px;
  padding: 10px 5px;
}
textarea.form-control {
  height: auto !important;
}
legend {
  font-size: 16px;
  margin: 0;
}
#close_m {
  right: 0;
  top: 0;
  position: absolute;
  background-color: red;
  border-radius: 99px;
  color: white;
  font-weight: bolder;
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;
}
@media (max-width: 675px) {
  .modal-body {
    overflow-y: scroll;
  }
  legend {
    display: none;
  }
  .modal-container {
    width: 90%;
    max-width: 600px !important;
  }
  .modal-wrapper {
    margin: 0 auto !important;
  }
}
</style>